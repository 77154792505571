
(function (factory) {
    /* global define */
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {
    var body_prototype = '\
		<label id="labelRadio" class="note-form-label">Hyperlink type</label>\
		<div class="form-group note-form-group">\
			  <input type="radio" style="cursor:pointer" class="note-link-radio" id="faq" name="gender" value="FAQ">\
				<label id="labelFAQ" for="FAQ">FAQ</label><br>\
				<input type="radio" style="cursor:pointer" class="note-link-radio" id="kb" name="gender" value="Knowledge Base">\
				<label id="labelKB" for="Knowledge Base">Knowledge Base</label><br>\
				<input type="radio" style="cursor:pointer" class="note-link-radio" id="dg" name="gender" value="Document Generator">\
				<label id="labelDG" for="Document Generator">Document Generator</label><br>\
                <input type="radio" style="cursor:pointer" class="note-link-radio" id="sd" name="gender" value="Standard Document">\
				<label id="labelSD" for="Standard Document">Standard Document</label>\
		</div>\
        <div class="form-group note-form-group">\
          <label id="labelSelect" class="note-form-label">Choose a link in this list</label>\
          <select id="target" class="note-link-list  note-form-control note-input" style="width: 100% !important;height: calc(1.5em + .75rem + 2px) !important;padding: .375rem .75rem !important;border: 1px solid #ced4da !important;"></select>\
        </div>\
        <div class="form-group note-form-group">\
        <label id="labelDisplay" class="note-form-label">Text to display</label>\
        <input  class="note-link-text form-control note-form-control  note-input" type="text" />\
      </div>';


    $.extend($.summernote.plugins, {
        'list-of-links': function (context) {
            var self = this,
                ui = $.summernote.ui,
                $editor = context.layoutInfo.editor,
                options = context.options,
                listUrl,
                listFaQ,
                listDG,
                listSD,
                dialogTitle;

            if (options.listOfLinks !== undefined) {
                listUrl = options.listOfLinks.listUrl;
                dialogTitle = options.listOfLinks.title;
            }
            if (dialogTitle === undefined) {
                dialogTitle = 'List of links';
            }



            context.memo('button.list-of-links', function () {
                return ui.button({
                    contents: '<span class="note-icon-link"> <label style="cursor:pointer;padding-left:4px">Application links</label></span>',
                    tooltip: dialogTitle,
                    container: 'body',
                    click: function () {
                        self.show();
                    }
                }).render();
            });


            this.loadList = function () {
                return $.Deferred(function (deferred) {
                    if (self.$radioButton.val() == '') {
                        self.$radioButton.val("Knowledge Base");
                    }
                    let locale = Number((localStorage.locale && localStorage.locale != '') ? localStorage.locale : 1043);
                    if (locale == 1043) {
                        document.getElementById('labelDisplay').innerHTML = "Tekst van de link";
                        document.getElementById('labelSelect').innerHTML = "Kies een item uit deze lijst";
                        document.getElementById('labelRadio').innerHTML = "Bron";
                        document.getElementById('buttonLink').innerHTML = "Link invoegen";
                    } else {
                        document.getElementById('labelDisplay').innerHTML = "Text to display";
                        document.getElementById('labelSelect').innerHTML = "Choose a link in this list";
                        document.getElementById('labelRadio').innerHTML = "Hyperlink type";
                        document.getElementById('buttonLink').innerHTML = "Insert Link";
                    }
                    let text = JSON.parse(localStorage.translations)['MenuItem-Faq'];
                    document.getElementById('labelFAQ').innerHTML = text;
                    let textKB = JSON.parse(localStorage.translations)['MenuItem-KnowledgeCenter'];
                    document.getElementById('labelKB').innerHTML = textKB;
                    let textDG = JSON.parse(localStorage.translations)['MenuItem-DocumentGenerator'];
                    document.getElementById('labelDG').innerHTML = textDG;
                    let textSD = JSON.parse(localStorage.translations)['MenuItem-StandardDocument'];
                    document.getElementById('labelSD').innerHTML = textSD;

                    //change selectboxes to selectize mode to be searchable
                    $("select").select2();

                    $('.note-link-list').html("")
                    $('.note-link-list').append($('<option hidden selected")">Select a Page...</option>'));
                    if ($("input[type=radio][name=gender]").val() == 'Document Generator') {
                        $('input:radio[name=gender]')[2].checked = true;
                        setTimeout(() => {
                            listUrl = options.listOfLinks.listDG;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }, 100);

                    } else if ($("input[type=radio][name=gender]").val() == 'Knowledge Base') {
                        $('input:radio[name=gender]')[1].checked = true;
                        setTimeout(() => {
                            listUrl = options.listOfLinks.listUrl;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }, 100);
                    } else if($("input[type=radio][name=gender]").val() == 'Standard Document'){
                        $('input:radio[name=gender]')[2].checked = true;
                        setTimeout(() => {
                            listUrl = options.listOfLinks.listSD;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }, 100);
                    }
                     else{
                        $('input:radio[name=gender]')[0].checked = true;
                        setTimeout(() => {
                            listUrl = options.listOfLinks.listFaQ;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }, 100);
                    }

                    $("input[type=radio][name=gender]").change(function () {
                        if ($(this).val() == 'Document Generator') {
                            listUrl = options.listOfLinks.listDG;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }
                        else if ($(this).val() == 'Knowledge Base') {
                            listUrl = options.listOfLinks.listUrl;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }
                        else if ($(this).val() == 'FAQ') {
                            listUrl = options.listOfLinks.listFaQ;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }
                        else if ($(this).val() == 'Standard Document') {
                            listUrl = options.listOfLinks.listSD;
                            if (self.data === undefined && listUrl !== undefined) {
                                $('.note-link-list').html("")
                                JSON.parse(listUrl).forEach(liUrl => {
                                    $('.note-link-list').append($('<option>', {
                                        value: liUrl[0],
                                        text: liUrl[1]
                                    }));
                                })

                                deferred.resolve();
                            } else {
                                deferred.resolve();
                            }
                        }
                    });


                   
                    if (self.data === undefined && listUrl !== undefined) {
                        $('.note-link-list').html("")
                        JSON.parse(listUrl).forEach(liUrl => {
                            $('.note-link-list').append($('<option>', {
                                value: liUrl[0],
                                text: liUrl[1]
                            }));
                        })

                        deferred.resolve();
                    } else {
                        deferred.resolve();
                    }
                });
            }
            this.initialize = function () {
                var $container = options.dialogsInBody ? $(document.body) : $editor;

                var buttonClass = 'btn btn-primary note-btn note-btn-primary note-link-btn';
                var footer = '<button id="buttonLink" type="submit" href="#" class="' + buttonClass + '">Insert link</button>';

                this.$dialog = ui.dialog({
                    title: dialogTitle,
                    body: body_prototype,
                    footer: footer
                }).render().appendTo($container);

                this.$linkText = self.$dialog.find('.note-link-text');
                this.$linkList = self.$dialog.find('.note-link-list');
                this.$linkBtn = self.$dialog.find('.note-link-btn');
                this.$radioButton = self.$dialog.find('.note-link-radio');


                // 				 $('input[type=radio][name=gender]').change(function() {
                //    if (this.$radioButton == 'Document Generator') {
                // 	//write your logic here
                // 	listUrl = options.listOfLinks.listDG;
                // 	}
                //   else if (this.$radioButton == 'Knowledge Base') {

                // 	listUrl = options.listOfLinks.listFaQ;
                //  }else{
                // 	 linkUrl = options.listOfLinks.listUrl;
                //  }
                //  });
            };

            this.show = function () {
                var linkInfo = context.invoke('editor.getLinkInfo');
                context.invoke('editor.saveRange');
                context.triggerEvent('dialog.shown');

                this.loadList().then(function () {
                    self.showLinkDialog(linkInfo).then(function (linkInfo) {
                        context.invoke('editor.restoreRange');
                        context.invoke('editor.createLink', linkInfo);
                    }).fail(function () {
                        context.invoke('editor.restoreRange');
                    });
                });
            };

            this.initButtonEvent = function (deferred, linkInfo) {
                self.$linkBtn.one('click', function (event) {
                    event.preventDefault();
                    var linkUrl = self.$linkList.val();
                    deferred.resolve({
                        range: linkInfo.range,
                        url: linkUrl,
                        text: self.$linkText.val(),
                        isNewWindow: false
                    });
                    ui.hideDialog(self.$dialog);
                });
            };

            this.showLinkDialog = function (linkInfo) {
                return $.Deferred(function (deferred) {
                    ui.onDialogShown(self.$dialog, function () {
                        self.$linkText.val(linkInfo.text);
                        self.$linkList.val(linkInfo.url);
                        self.initButtonEvent(deferred, linkInfo);
                    });

                    ui.onDialogHidden(self.$dialog, function () {
                        self.$linkBtn.off('click');
                        if (deferred.state() === 'pending') {
                            deferred.reject();
                        }
                    });
                    ui.showDialog(self.$dialog);
                });
            };
        }
    });
}));
